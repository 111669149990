import React from "react"

import Layout from "./../layouts/Layout/Layout"
import HeroSubpage from "../sections/HeroSubpage/HeroSubpage"
import ThreeColSection from "../sections/ThreeColSection/ThreeColSection"
import FeaturesList from "../sections/FeaturesList/FeaturesList"

import Background from "../assets/images/hero_fund.jpg"
import IconScholarship from "../assets/svg/icon_scholarship.svg"
import IconSciWallet from "../assets/svg/icon_wallet.svg"
import IconID from "../assets/svg/icon_identifiacationCard.svg"
import IconHandBook from "../assets/svg/icon_handBook.svg"

const Contact = () => (
  <Layout>
    <HeroSubpage title="Fundusz" backgroundImage={Background} />
    <ThreeColSection
      heading="Fundusz stypendialny"
      headingColor="#9A4646"
      article1={
        "<h3>Pomagamy studiować w Trójmieście</h3>" +
        "<p>Fundusz Stypendialny to ważna część Programu Alkantara. Jego celem jest wyrównywanie szans młodych i zdolnych ludzi przez pomoc w finansowaniu potrzeb związanych ze studiami w Gdańsku, Gdyni czy w Sopocie. Możesz być wyłącznie uczestnikiem Akademii Alkantara lub korzystać zarówno z możliwości Akademii, jak i Funduszu Stypendialnego. </p>"
      }
      article2={
        "<h3>Jak to działa?</h3>" +
        "<p><strong>Fundusz Stypendialny</strong> stworzyliśmy z myślą o osobach, które z różnych przyczyn nie mogą podjąć wymarzonych studiów w Trójmieście. Wysokość stypendium ustalamy tak, by umożliwić naszym uczestnikom utrzymanie w mieście, w którym chcą studiować. Zawsze bierzemy pod uwagę indywidualne potrzeby uczestnika, uwzględniając koszty utrzymania oraz zapewnienie niezbędnych warunków do nauki i rozwoju. Spełniamy marzenia o studiach i pomagamy zorganizować życie w nowym miejscu.</p>"
      }
    />
    <FeaturesList
      noBorder
      items={[
        {
          backgroundColor: "#9A4646",
          svg: IconScholarship,
          text: "Środki niezbędne do podjęcia studiów w Trójmieście",
        },
        {
          backgroundColor: "#9A4646",
          svg: IconSciWallet,
          text: "Finansowe wsparcie adekwatne do indywidualnych potrzeb",
        },
        {
          backgroundColor: "#9A4646",
          svg: IconHandBook,
          text: "Szansa na lepszą edukację",
        },
        {
          backgroundColor: "#9A4646",
          svg: IconID,
          text: "Mocny start na rynku pracy",
        },
      ]}
    />
  </Layout>
)

export default Contact
